import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function TwoColumns({key, index, ...props}) {


  const className = ''
  let reverse = false
  let alignBackground = 'top-0'
  let colourSize = 'h-full'
  const secondImage = ''

  const section = props?.twoColumns
  const textCard = section?.textCard

  const backgroundColour = section?.backgroundColour
  const backgroundColourSize = backgroundColour?.colourSize
  const backgroundColourAlign = backgroundColour?.alignColour
  const alignImage = section?.alignImage

  if(alignImage === 'right'){
    reverse = true
  }

  if(backgroundColourAlign === 'bottom'){
    alignBackground = 'bottom-0'
  }


  return (
    <section id={section.fieldGroupName + index} className={` ${props?.twoColumns?.secondImage && 'lg:mb-72'} ${backgroundColour?.colour ? `${backgroundColour?.padding?.top === 'normal' && 'pt-20 md:pt-36'} ${backgroundColour?.padding?.bottom === 'normal' && 'pb-20 md:pb-36'} ${props?.twoColumns?.secondImage && 'lg:mb-72'}` : `my-20 md:my-36`} ${backgroundColour?.colour === 'navy' && `text-white`} relative ${className}`}>
      {backgroundColour?.colour && (
        <div className={`absolute left-0 right-0 ${alignBackground} h-${backgroundColourSize.toLowerCase()} ${backgroundColour?.colour && `bg-${backgroundColour?.colour}`}`}></div>
      )}
      <div className={`container relative ${props?.twoColumns?.thirdImage && 'lg:pb-20'} `}>
        <div className="grid grid-cols-12 gap-y-12 md:gap-y-0 relative grid-flow-row-dense">
          <div className={`col-span-12 md:col-span-6 ${reverse && 'md:col-start-7'}`}>
            <FadeInWhenVisible>
              {props?.twoColumns?.image && <Image data={props?.twoColumns?.image}  />}
            </FadeInWhenVisible>
          </div>
          <div className={`col-span-12 md:col-span-5 flex flex-col justify-center relative ${reverse ? 'md:col-start-1' : 'md:col-start-8'}`}>
            <TextCard className="" {...textCard} />
              {secondImage && (
                <div className="absolute hidden lg:block top-[calc(100%+7rem)] z-10 right-0 w-80">
                  <Image className="" />
                </div>
              )}
              <FadeInWhenVisible className={`hidden lg:block absolute ${props?.twoColumns?.alignSecondImage === 'top' ? 'bottom-[90%]' : `${backgroundColour?.colour ? 'top-full' : 'top-[90%]'}`}  w-72 ${!reverse && 'right-0'} `}>
              {props?.twoColumns?.secondImage && <Image data={props?.twoColumns?.secondImage} className={`aspect-w-15 aspect-h-16 w-full`} />}
              </FadeInWhenVisible>
              <FadeInWhenVisible className={`hidden lg:block absolute top-[122%] left-3/4 w-64`}>
                {props?.twoColumns?.thirdImage && <Image data={props?.twoColumns?.thirdImage} className={`aspect-w-9 aspect-h-10 w-full`} />}
              </FadeInWhenVisible>
          </div>
        </div>
      </div>
    </section>
  )
}

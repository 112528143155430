import React from 'react';
import { useField } from 'formik';

export default function CheckboxField(props) {
  const { label, reverse = false, onChange, checked, ...other } = props;
  const [field, meta, helpers] = useField({ ...props, type: 'checkbox' });
  const isInvalid = meta.touched && meta.error;

  // Handle changes to the checkbox
  const handleChange = (event) => {
    if (onChange) {
      onChange(event); // Call the parent handler if provided
    }
    field.onChange(event); // Ensure Formik updates the state
  };

  return (
    <div
      className={`flex items-start relative group ${props.divclassname} ${
        !isInvalid ? 'border-grey focus-within:border-blue' : 'border-[#ff0000]'
      }`}
    >
      <input
        type="checkbox"
        id={props.name}
        className={`border bg-transparent appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out ${
          !isInvalid ? 'border-navy focus-within:border-navy' : 'border-[#ff0000]'
        } border-opacity-30 ${reverse && 'order-1'} ${props.inputClassName}`}
        style={{ flex: '0 0 18px', height: '18px' }}
        {...field}
        {...other}
        onChange={handleChange}
        checked={checked !== undefined ? checked : field.value} // Use the prop if provided
      />
      <label
        htmlFor={props.name}
        className={`text-sm cursor-pointer ${reverse ? 'mr-auto' : 'pl-4'} ${props.labelClassName}`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      {isInvalid && (
        <div className="text-[12px] text-error flex font-medium items-center mt-1">
          <div className="w-2 h-2 relative mr-1 mt-px">
            <div className="w-full h-0.5 bg-error block absolute top-1/2 transform -translate-y-1/2 rotate-45"></div>
            <div className="w-full h-0.5 bg-error block absolute top-1/2 transform -translate-y-1/2 -rotate-45"></div>
          </div>
          {meta.error}
        </div>
      )}
    </div>
  );
}

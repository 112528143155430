
import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'

export default function HeroHeading({className, heading, ...hero}) {

  return (
    <section className={`mb-20 md:mb-36 bg-sand ${className} `}>
      <div className="container relative">
        <div className="py-20 pt-32 md:py-36 md:pt-52">
          <div className="max-w-xl mx-auto text-center">
            <TextCard {...hero.textCard}  />
          </div>
        </div>
        {hero.illustration1 && <Image data={hero.illustration1} className="!absolute hidden lg:block top-24 right-12 w-56 h-44" objectFit="contain" />}
        {hero.illustration2 && <Image data={hero.illustration2} className="!absolute hidden lg:block bottom-8 left-12 w-56 h-44" objectFit="contain"/>}
      </div>
    </section>
  )
}

import React from "react";
import TextCard from '~/components/molecules/TextCard'
import IconSocial from '~/components/atoms/IconSocial'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Ctas(props) {

  return (
    <section className="my-20 md:my-36">
      <div className="container">
        <div className="grid grid-cols-12 gap-y-6 md:gap-x-5 md:gap-y-0">
          <FadeInWhenVisible className="col-span-12 md:col-span-6 bg-rose p-16 flex flex-col items-center">
            <TextCard {...props.resourcesCta.textCard} className="!space-y-8" />
          </FadeInWhenVisible>
          <FadeInWhenVisible className="col-span-12 md:col-span-6 bg-seafoam p-16 flex flex-col items-center space-y-8">
            <TextCard {...props.socialsCta.textCard} />
            <FadeInWhenVisible className="flex items-center space-x-12">
              <a href="https://twitter.com/city_relay" className="hover:opacity-50 transition-opacity duration-200 ease-in-out" target="_blank">
                <IconSocial className="w-6 h-auto" type="twitter"/>
              </a>
              <a href="https://www.facebook.com/CityRelayLondon/" className="hover:opacity-50 transition-opacity duration-200 ease-in-out" target="_blank">
                <IconSocial className="w-6 h-auto" />
              </a>
              <a href="https://www.instagram.com/city_relay/" className="hover:opacity-50 transition-opacity duration-200 ease-in-out" target="_blank">
                <IconSocial className="w-6 h-auto" type="instagram"/>
              </a>
            </FadeInWhenVisible>
          </FadeInWhenVisible>
        </div>
      </div>
    </section>
  );
}


import * as React from 'react';
import { Helmet } from 'react-helmet';
import { languagePages } from '~/data/languagePages';

export default function CustomSEO({ seo }) {
  const imageSrc = seo?.image?.localFile?.publicURL;
  const imageAlt = seo?.image?.altText;
  const imageType = seo?.image?.localFile?.extension;

  const alternateLanguagesUrls = languagePages.find((element) => element.includes(seo?.canonical));

  return (
    <Helmet>
      <html lang="en-GB" />
      <meta property="og:url" content={seo?.canonical} />
      <meta
        property="og:image"
        content={imageSrc ? `https://cityrelay.com${imageSrc}` : 'https://cityrelay.com/cityrelay-logo.jpg'}
      />
      <meta property="og:image:type" content={`image/${imageType ?? 'jpg'}`} />
      <meta property="og:image:alt" content={imageAlt ? imageAlt : 'City Relay'} />
      <meta
        name="twitter:image"
        content={imageSrc ? `https://cityrelay.com${imageSrc}` : 'https://cityrelay.com/cityrelay-logo.jpg'}
      />
      {alternateLanguagesUrls?.map((url, index) => (
        <link key={index} rel="alternate" href={url}></link>
      ))}
      {!alternateLanguagesUrls && <link rel="alternate" href="https://cityrelay.com/"></link>}
    </Helmet>
  );
}
